import { Button, Typography } from "@mui/material";
import { AuthContext } from "context";
import { useSelectedLanguage } from "language";
import { useContext, useEffect, useState } from "react";
import { CSVDownload } from "react-csv";
import { CompanyReport, CompanyWithIssues } from "types";
import {
  getCompaniesWithIssues,
  setCsvIssueHeaders,
  translateManagement,
  translateRisk,
} from "utils";
const _ = require("lodash");

const CsvDownloader = (props: any) => {
  const [csvData, setCsvData] = useState({ data: [] as CompanyWithIssues[] });
  const [csvEstimatedData, setCsvEstimatedData] = useState({
    data: [] as CompanyReport[],
  });
  const [loading, setLoading] = useState(false);
  const [loadingSummaryBtn, setLoadingSummaryBtn] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [downloadCsv, setDownloadCsv] = useState(false);
  const { userData } = useContext(AuthContext);

  const {
    selectedLanguage,
    selectedDictionary,
    selectedTypeTitles,
    selectedCsvHeaders,
  } = useSelectedLanguage();
  useEffect(() => {
    if (downloadCsv) {
      const loadCsvHeaders = async () => {
        await setCsvIssueHeaders(selectedCsvHeaders, selectedDictionary);
        const headers = _.uniqBy(
          [...selectedCsvHeaders],
          (header: any) => header.label
        );
        setLoading(true);
        const rawCompanies = await getCompaniesWithIssues(props);
        const detailedData = await generateDetailedData(rawCompanies, headers);
        setCsvData({ data: detailedData });
        setLoading(false);
        setLoaded(true);
      };
      loadCsvHeaders();
    }
  }, [downloadCsv]);

  const companySummaryHeaders: any = [
    { label: "נייר ערך", key: "taseId" },
    { label: "מספר מנפיק", key: "issuerId" },
    { label: "שם חברה", key: "name" },
    { label: "ענף", key: "primarySector" },
    { label: "ESG ציון", key: "grade" },
    { label: "ESG דירוג", key: "letterGrade" },
    { label: "סיכומי הערכות", key: "analystSummary" },
  ];
  const companySummaryHeadersEn: any = [
    { label: "Security", key: "taseId" },
    { label: "Issuer Id", key: "issuerId" },
    { label: "Name", key: "name" },
    { label: "Primary Sector", key: "primarySector" },
    { label: "ESG Grade", key: "grade" },
    { label: "Analyst Summary", key: "analystSummary" },
  ];

  const csvDetailData: any[] = [];

  const estimatedSummaryData: any[] = [];

  const markdownRegex = /([*_~`]|^#|#|<u>|<\/u>|\>|\[.*?\]\(.*?\))/g;

  const generateEstimatedData = async (companies: CompanyWithIssues[]) => {
    if (!companies.length) return estimatedSummaryData;
    for (const company of companies) {
      for (const security of company.securities) {
        const reportObject: any = {
          taseId: security,
          issuerId: company.issuerId,
          name: selectedLanguage === "en" ? company.name_en : company.name,
          primarySector: company.primarySector
            ? company.primarySector.name
            : "",
          grade: company.esgGrade.grade,
          letterGrade: company.esgGrade.letterGrade,
          analystSummary: company.analystSummary
            ? sanitizeTextForExport(company.analystSummary)
            : "",
        };
        estimatedSummaryData.push(reportObject);
      }
    }
    return estimatedSummaryData;
  };

  const sanitizeTextForExport = (text: string) => {
    text = text.replace(/\"/g, '""');
    text = text.replace(/[\r\n]|[\n]/gm, "\n");
    text = text.replace(markdownRegex, "");
    return `""${text}""`;
  };

  const getCsvEstimatedData = async () => {
    setLoadingSummaryBtn(true);
    const rawCompanies = await getCompaniesWithIssues(props);
    const estimatedData = await generateEstimatedData(rawCompanies);
    setCsvEstimatedData({ data: estimatedData });
    setLoadingSummaryBtn(false);
    setLoaded(true);
  };

  const generateDetailedData = async (
    companies: CompanyWithIssues[],
    headers: any[]
  ) => {
    if (!companies.length) return csvDetailData;
    for (const company of companies) {
      for (const security of company.securities) {
        const d: any = {
          security: security,
          issuerId: company?.issuerId,
          name: selectedLanguage === "en" ? company?.name_en : company?.name,
          sector: company?.primarySector?.name,
          esgScore: company?.esgGrade?.grade,
          relativeEsgScore: company?.esgGrade?.gradeRelativeToSectorAverage
            ? selectedTypeTitles[
                company?.esgGrade?.gradeRelativeToSectorAverage
              ]
            : "",
          percentileInSector: company?.esgGrade?.percentileInSector,
          percentileInGeneral: company?.esgGrade?.percentileInGeneral,
          envScore: company?.environmental?.grade,
          relativeEnvScore: company?.environmental?.gradeRelativeToSectorAverage
            ? selectedTypeTitles[
                company?.environmental?.gradeRelativeToSectorAverage
              ]
            : "",
          socialScore: company?.social?.grade,
          relativeSocialScore: company?.social?.gradeRelativeToSectorAverage
            ? selectedTypeTitles[company?.social?.gradeRelativeToSectorAverage]
            : "",
          govScore: company?.governance?.grade,
          relativeGovScore: company?.governance?.gradeRelativeToSectorAverage
            ? selectedTypeTitles[
                company?.governance?.gradeRelativeToSectorAverage
              ]
            : "",
          incidentsFlag:
            company?.governance?.incidentsFlag ||
            company?.social?.incidentsFlag ||
            company?.environmental?.incidentsFlag
              ? selectedDictionary.yes
              : selectedDictionary.no,
          accGradesWithoutFactor:
            company?.esgGrade?.grade &&
            company?.esgGrade?.accGradesWithoutFactor &&
            company?.esgGrade?.grade -
              company?.esgGrade?.accGradesWithoutFactor,
          badActivities: company?.badActivities
            ?.map(
              (a) =>
                `${selectedTypeTitles[a.type]} : ${
                  selectedTypeTitles[a.involvement]
                }`
            )
            .join("; "),
          esgActivities: company?.sdgActivities
            ?.map(
              (a) =>
                `${selectedTypeTitles[a.type]} : ${
                  selectedTypeTitles[a.involvement]
                }`
            )
            .join("; "),
          letterGrade: company?.esgGrade?.letterGrade,
          climateGrade: company?.climateGrade?.grade,
        };

        for (const header of headers) {
          if (header.key.includes(` - ${(selectedDictionary as any).grade}`)) {
            d[header.key] = translateManagement(
              company?.issues?.find(
                (i) =>
                  i.label + ` - ${(selectedDictionary as any).grade}` ===
                  header.label
              )?.management
            );
          } else if (
            header.key.includes(` - ${(selectedDictionary as any).exposure}`)
          ) {
            d[header.key] = translateRisk(
              company?.issues?.find(
                (i) =>
                  i.label + ` - ${(selectedDictionary as any).exposure}` ===
                  header.label
              )?.risk
            );
          }
        }
        csvDetailData.push(d);
      }
    }
    return csvDetailData;
  };

  const getCsvData = async () => {
    setDownloadCsv(true);
  };

  const buttonsData = [
    {
      loading: loading,
      getCsvData: getCsvData,
      buttonText: (selectedDictionary as any).elaborateDataExport,
      csvData: csvData,
      headers: selectedCsvHeaders,
    },
    {
      loading: loadingSummaryBtn,
      getCsvData: getCsvEstimatedData,
      buttonText: (selectedDictionary as any).analysisSummary,
      csvData: csvEstimatedData,
      headers:
        selectedLanguage === "en"
          ? companySummaryHeadersEn
          : companySummaryHeaders,
    },
  ];

  return (
    <>
      {buttonsData.map((item, index) => (
        <Button
          key={index}
          variant="outlined"
          style={{ width: "13.6em", marginTop: "0.1em" }}
        >
          <Typography
            variant="h5"
            color="primary"
            onClick={item.getCsvData}
            sx={{ cursor: "pointer" }}
          >
            {item.loading
              ? (selectedDictionary as any).downloading
              : item.buttonText}
          </Typography>
          {!!item.csvData.data.length && (
            <CSVDownload
              target="_self"
              data={item.csvData.data}
              headers={item.headers}
            />
          )}
        </Button>
      ))}
    </>
  );
};

export default CsvDownloader;
