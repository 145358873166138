import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse/Collapse";
import IconButton from "@mui/material/IconButton/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import redFlag from "assets/flag.svg";
import { Direction, useSelectedLanguage } from "language";
import { FC, useState } from "react";
import { Asset } from "types";
interface TableProps {
  assets: Asset[];
}
const AssetTable: FC<TableProps> = ({ assets }) => {
  const [toggle, setToggle] = useState(false);
  const { selectedDictionary, selectedDirection } = useSelectedLanguage();
  const labels = {
    assetId: `${(selectedDictionary as any).funds.assetId}`,
    company: `${(selectedDictionary as any).funds.company}`,
    isRedFlag: `${(selectedDictionary as any).funds.isRedFlag}`,
    weight: `${(selectedDictionary as any).funds.weight}`,
    grade: `${(selectedDictionary as any).funds.grade}`,
    normalizedWeight: `${(selectedDictionary as any).funds.normalizedWeight}`,
    weightedGrade: `${(selectedDictionary as any).funds.weightedGrade}`,
  };
  const columns = Object.values(labels);
  return (
    <TableContainer component={Paper}>
      <IconButton
        aria-label="expand/collapse table"
        size="small"
        onClick={() => {
          setToggle(!toggle);
        }}
      >
        {toggle ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
      <Collapse in={toggle} timeout="auto" unmountOnExit>
        <Table sx={{ minWidth: 650 }} aria-label="fund table">
          <TableHead>
            <TableRow>
              {columns.map((head, i) => (
                <TableCell align="center" key={i}>
                  {head}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {assets.map((asset, i) => (
              <TableRow
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {Object.keys(labels).map((key, index) => (
                  <TableCell
                    key={index}
                    component="th"
                    scope="row"
                    align={
                      selectedDirection === Direction.LTR ? "left" : "right"
                    }
                  >
                    {key === "isRedFlag" ? (
                      (asset as any)[key] ? (
                        <img src={redFlag} alt="Red Flag"></img>
                      ) : (
                        "-"
                      )
                    ) : (
                      (asset as any)[key] || "-"
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Collapse>
    </TableContainer>
  );
};

export default AssetTable;
