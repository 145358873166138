import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { AuthContext, CompanyContext } from "context";
import { Direction, useSelectedLanguage } from "language";
import { useContext } from "react";
import { usePrintStore } from "stores";
import { SectorCompany } from "types";
import * as SC from "./style";
import calculatLetterGrade from "./utils";

function StickyHeadTable(props: any) {
  const { company } = useContext(CompanyContext);
  const { printing } = usePrintStore();
  const { userData } = useContext(AuthContext);
  const { gradeConfig } = props;
  const { selectedDictionary, selectedDirection } = useSelectedLanguage();
  const checkUnusualEvents = (company: SectorCompany) => {
    const { events, eventsFlag } = company;
    if (eventsFlag === "Critical") {
      return selectedDictionary.criticalEvents;
    }
    if (!events) {
      return selectedDictionary.no;
    } else {
      return selectedDictionary.yes;
    }
  };

  return (
    <Paper
      sx={{
        width: "100%",
        overflowY: printing ? "visible" : "auto",
        boxShadow: "0",
      }}
    >
      <TableContainer sx={{ maxHeight: printing ? "none" : "20rem" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#FBFBFB" }}>
              <TableCell
                key="numberHeader"
                size="small"
                align="center"
              ></TableCell>
              <TableCell key="nameHeader" size="medium"></TableCell>
              <TableCell key="unusualEvents" size="medium" align="center">
                <Typography color="text.secondary">
                  {(selectedDictionary as any).companiesComparisonCell1}
                </Typography>
              </TableCell>
              <TableCell key="ESGHeader" size="medium" align="center">
                <Typography color="text.secondary">ESG</Typography>
              </TableCell>
              {!userData.letterGrading ? (
                <>
                  <TableCell
                    key="environmentalGradeHeader"
                    size="medium"
                    align="center"
                  >
                    <Typography color="text.secondary">
                      {(selectedDictionary as any).companiesComparisonCell2}
                    </Typography>
                  </TableCell>
                  <TableCell
                    key="socialGradeHeader"
                    size="medium"
                    align="center"
                  >
                    <Typography color="text.secondary">
                      {(selectedDictionary as any).companiesComparisonCell3}
                    </Typography>
                  </TableCell>
                  <TableCell
                    key="governanceGradeHeader"
                    size="medium"
                    align="center"
                  >
                    <Typography color="text.secondary">
                      {(selectedDictionary as any).companiesComparisonCell4}
                    </Typography>
                  </TableCell>
                </>
              ) : (
                ""
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {company?.sectorCompanies?.map((row: SectorCompany, i: number) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  sx={{
                    backgroundColor:
                      row.id === company.id ? "#E8F6F9" : "#FBFBFB",
                    border: "1px solid #E5E5E5",
                  }}
                >
                  <TableCell key={row.id + "number"} align="center">
                    {i + 1}
                  </TableCell>
                  <TableCell
                    key={row.id + "name"}
                    align={
                      selectedDirection === Direction.LTR ? "left" : "right"
                    }
                  >
                    {row.name || "-"}
                  </TableCell>
                  <TableCell key={row.id + "unusualEvents"} align="center">
                    <span>
                      {row.incidentsFlag
                        ? selectedDictionary.yes
                        : selectedDictionary.no}
                    </span>
                  </TableCell>
                  <TableCell key={row.id + "ESG"} align="center">
                    {userData.letterGrading
                      ? calculatLetterGrade(row.grade, gradeConfig, row)
                      : row.grade?.toString() || "-"}
                  </TableCell>
                  {!userData.letterGrading ? (
                    <TableCell
                      key={row.id + "environmentalGrade"}
                      align="center"
                    >
                      {row.environmentalGrade?.toString() || "-"}{" "}
                    </TableCell>
                  ) : (
                    ""
                  )}
                  {!userData.letterGrading ? (
                    <TableCell key={row.id + "socialGrade"} align="center">
                      {row.socialGrade?.toString() || "-"}
                    </TableCell>
                  ) : (
                    ""
                  )}
                  {!userData.letterGrading ? (
                    <TableCell key={row.id + "governanceGrade"} align="center">
                      {row.governanceGrade?.toString() || "-"}
                    </TableCell>
                  ) : (
                    ""
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

const OutlinedCard = (props: any) => {
  const { gradeConfig } = props;
  const { selectedLanguage, selectedDictionary } = useSelectedLanguage();

  return (
    <SC.Card variant="outlined">
      <CardContent>
        <Typography variant="h4" gutterBottom>
          {(selectedDictionary as any).companiesComparisonCell5}
        </Typography>
        <StickyHeadTable {...{ gradeConfig }} />
      </CardContent>
    </SC.Card>
  );
};

export default OutlinedCard;
